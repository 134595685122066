import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
// componenets
import Gallery from '@/components/Gallery/Gallery.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let PackageContent = class PackageContent extends Vue {
};
__decorate([
    Prop()
], PackageContent.prototype, "activePackage", void 0);
PackageContent = __decorate([
    Component({
        components: {
            Gallery,
            MarkdownFormatter,
        },
    })
], PackageContent);
export default PackageContent;
