import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
// components
let SocialLinks = class SocialLinks extends Vue {
    constructor() {
        super(...arguments);
        this.socialLinks = [
            { name: 'Facebook', icon: ['fab', 'facebook'], keywords: ['facebook'] },
            { name: 'LinkedIn', icon: ['fab', 'linkedin'], keywords: ['linkedin'] },
            { name: 'Instagram', icon: ['fab', 'instagram'], keywords: ['instagram'] },
            { name: 'Twitter', icon: ['fab', 'twitter'], keywords: ['twitter'] },
            { name: 'YouTube', icon: ['fab', 'youtube'], keywords: ['youtube', 'youtu.be'] },
            { name: 'Whatsapp', icon: ['fab', 'whatsapp'], keywords: ['whatsapp'] },
            { name: 'Email', icon: ['fal', 'envelope'], keywords: ['mailto', '@'] },
            { name: 'Telegram', icon: ['fab', 'telegram-plane'], keywords: ['telegram'] },
            { name: 'Pinterest', icon: ['fab', 'pinterest'], keywords: ['pinterest'] },
            { name: 'Vimeo', icon: ['fab', 'vimeo'], keywords: ['vimeo'] },
            { name: 'Tiktok', icon: ['fab', 'tiktok'], keywords: ['tiktok'] },
            { name: 'Website', icon: ['fal', 'globe'], keywords: ['http'] },
        ];
    }
    mounted() { }
    socailIcon(link) {
        for (let i = 0; i < this.socialLinks.length; i++) {
            const item = this.socialLinks[i];
            for (let i = 0; i < item.keywords.length; i++) {
                const keyword = item.keywords[i];
                if (link.includes(keyword)) {
                    return item.icon;
                }
            }
        }
    }
};
__decorate([
    Prop()
], SocialLinks.prototype, "links", void 0);
__decorate([
    Prop({ default: true })
], SocialLinks.prototype, "showLabel", void 0);
SocialLinks = __decorate([
    Component
], SocialLinks);
export default SocialLinks;
