import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let Expander = class Expander extends Vue {
    constructor() {
        super(...arguments);
        this.expanded = false;
        this.labelClasses = '';
    }
    mounted() {
        this.expanded = this.open;
    }
    toggleExpand() {
        this.expanded = !this.expanded;
    }
};
__decorate([
    Prop()
], Expander.prototype, "label", void 0);
__decorate([
    Prop({ default: false })
], Expander.prototype, "open", void 0);
Expander = __decorate([
    Component
], Expander);
export default Expander;
