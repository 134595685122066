import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let ExpanderMap = class ExpanderMap extends Vue {
    constructor() {
        super(...arguments);
        this.expanded = false;
        this.mapLink = '';
        this.mapKey = 'AIzaSyClGC1u6gUMuyfa1GYH4mljUHYoRZpjAHA';
    }
    mounted() {
        this.mapLink = `https://www.google.com/maps/@${this.lat},${this.long},12z?hl=en-GB`;
    }
    toggleExpand() {
        this.expanded = !this.expanded;
    }
};
__decorate([
    Prop()
], ExpanderMap.prototype, "location", void 0);
__decorate([
    Prop()
], ExpanderMap.prototype, "lat", void 0);
__decorate([
    Prop()
], ExpanderMap.prototype, "long", void 0);
__decorate([
    Prop()
], ExpanderMap.prototype, "size", void 0);
__decorate([
    Prop({ default: true })
], ExpanderMap.prototype, "link", void 0);
__decorate([
    Prop({ default: true })
], ExpanderMap.prototype, "showMap", void 0);
ExpanderMap = __decorate([
    Component
], ExpanderMap);
export default ExpanderMap;
