import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let OfferCard = class OfferCard extends Vue {
};
__decorate([
    Prop()
], OfferCard.prototype, "offer", void 0);
OfferCard = __decorate([
    Component
], OfferCard);
export default OfferCard;
