import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ProfileService from '@/services/user';
import PackageService from '@/services/packages';
// Components
import Btn from '@/components/Button/Btn.vue';
import PackageCard from '@/views/Packages/Components/PackageCard/PackageCard.vue';
import TextInput from '@/components/FormInputs/TextInput/TextInput.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import SelectInput from '@/components/FormInputs/SelectInput/SelectInput.vue';
let Packages = class Packages extends Vue {
    constructor() {
        super(...arguments);
        this.packages = [];
        // Destination
        this.destinations = [];
        this.destination = null;
        this.destinationOptions = [];
        // Experience
        this.experiences = [];
        this.experience = null;
        this.experienceOptions = [];
        // Currency
        this.currencyOptions = [];
        this.currency = null;
        this.currencies = [];
        this.loading = true;
        this.paginationLoaded = false;
        this.showFilters = true;
        this.onlyFavourites = false;
        this.coverImageDesc = 'Amazing packages at the BEST prices. Plus, our team of seasoned safari experts on call.';
        this.onlyNonMalaria = false;
        this.cardIcon = ['fal', 'ellipsis-v'];
    }
    async currencyUpdated(newVal) {
        if (this.currency != null) {
            let selected = this.currencies.filter((c) => {
                return c.code === this.currency;
            });
            await this.$store.dispatch('app/setCurrency', selected[0]);
        }
        else {
            await this.$store.dispatch('app/setCurrency', null);
        }
    }
    async destinationUpdated(newVal) {
        this.loading = true;
        // Set the destination & rest page
        this.packages = [];
        await this.$store.dispatch('destinations/setDestination', newVal);
        if (this.$route.query.destination !== newVal) {
            await this.$store.dispatch('packages/setPage', 1);
        }
        else {
            await this.$store.dispatch('packages/setPage', this.$route.query.page);
        }
        await this.getPackages();
        this.loading = false;
    }
    async experienceUpdated(newVal) {
        this.loading = true;
        // Set the experience & rest page
        await this.$store.dispatch('packages/setExperience', newVal);
        if (this.$route.query.experience !== newVal) {
            await this.$store.dispatch('packages/setPage', 1);
        }
        else {
            await this.$store.dispatch('packages/setPage', this.$route.query.page);
        }
        await this.getPackages();
        this.loading = false;
    }
    created() {
        if (history.state.destination && history.state.experience && history.state.page) {
            if (this.$route.fullPath ===
                `/packages?destination=${history.state.destination}&experience=${history.state.experience}&page=${history.state.page}`) {
                return;
            }
            else {
                this.$router.push({
                    path: `/packages?destination=${history.state.destination}&experience=${history.state.experience}&page=${history.state.page}`,
                });
            }
        }
        else {
            if (history.state.destination && history.state.page) {
                if (this.$route.fullPath ===
                    `/packages?destination=${history.state.destination}&page=${history.state.page}`) {
                    return;
                }
                else {
                    this.$router.push({
                        path: `/packages?destination=${history.state.destination}&page=${history.state.page}`,
                    });
                }
            }
            if (history.state.experience && history.state.page) {
                if (this.$route.fullPath ===
                    `/packages?experience=${history.state.experience}&page=${history.state.page}`) {
                    return;
                }
                else {
                    this.$router.push({
                        path: `/packages?experience=${history.state.experience}&page=${history.state.page}`,
                    });
                }
            }
        }
    }
    async mounted() {
        await this.resetPackageValues();
        if (this.$route.query.non_malaria) {
            await this.$store.dispatch('packages/setOnlyNonMalaria', this.$route.query.non_malaria);
            this.onlyNonMalaria = this.$store.getters['packages/getOnlyNonMalaria'];
        }
        if (this.$route.query.favorite) {
            await this.$store.dispatch('packages/setOnlyFavourites', this.$route.query.favorite);
            this.onlyFavourites = this.$store.getters['packages/getOnlyFavourites'];
        }
        if (this.$route.query.page) {
            await this.$store.dispatch('packages/setPage', this.$route.query.page);
        }
        if (this.$route.query.destination) {
            await this.$store.dispatch('destinations/setDestination', this.$route.query.destination);
            this.destination = this.$store.getters['destinations/selectedDestination'];
        }
        if (this.$route.query.experience) {
            await this.$store.dispatch('packages/setExperience', this.$route.query.experience);
            this.experience = this.$store.getters['packages/selectedExperience'];
        }
        // Get all the packages
        this.getPackages();
        this.paginationLoaded = true;
        this.loading = false;
        // setup the destination selector
        this.destinationSelectorSetup();
        // setup the destination selector
        this.experienceSelectorSetup();
        // Setup the currency selector
        this.currencySelectorSetup();
        if (this.$store.getters['auth/loggedIn']) {
            await ProfileService.updateLastActivity("packages");
        }
    }
    async getPackages() {
        this.loading = true;
        try {
            const response = await PackageService.get();
            this.packages = response.data.packages;
            this.$store.dispatch('pagination/setPagination', response.data.pagination);
            this.$store.dispatch('user/resetSingleResourceCount', 'packages');
            this.loading = false;
        }
        catch (e) {
            this.$store.dispatch('notification/showError', e.response.data.message);
            this.loading = false;
        }
    }
    async destinationSelectorSetup() {
        // Setup the destination selector
        await this.$store.dispatch('destinations/getDestinations', 'package');
        this.destinations = this.$store.getters['destinations/destinations'];
        // Initial data
        let TemDestinations = [
            {
                name: 'All Places',
                value: '',
                selected: false,
            },
        ];
        // Loop through destinations
        this.destinations?.forEach((destination) => {
            TemDestinations.push({
                name: `${destination.name}`,
                value: destination.id,
                selected: false,
            });
            // If destination has children
            if (destination.children) {
                // Loop through children
                destination.children.forEach((child) => {
                    TemDestinations.push({
                        name: `-- ${child.name}`,
                        value: child.id,
                        selected: false,
                    });
                });
            }
        });
        // set the destination option selector
        this.destinationOptions = TemDestinations;
        if (this.$store.getters['destinations/selectedDestination'] !== null) {
            this.destination = this.$store.getters['destinations/selectedDestination'];
        }
    }
    async experienceSelectorSetup() {
        // Setup the experience selector
        await this.$store.dispatch('packages/getExperiences');
        this.experiences = this.$store.getters['packages/experiences'];
        // Initial data
        let TemExperiences = [
            {
                name: 'All Experiences',
                value: '',
                selected: false,
            },
        ];
        this.experiences?.map((experience) => {
            TemExperiences.push({
                name: `${experience.name}`,
                value: experience.id,
                selected: false,
            });
        });
        this.experienceOptions = TemExperiences;
        if (this.$store.getters['packages/selectedExperience'] !== null) {
            this.experience = this.$store.getters['packages/selectedExperience'];
        }
    }
    async currencySelectorSetup() {
        // Setup the currency selector
        await this.$store.dispatch('app/getCurrencies');
        this.currencies = this.$store.getters['app/currencies'];
        this.currencyOptions = this.currencies?.map((currency) => {
            return {
                name: `${currency.code} - ${currency.name}`,
                value: currency.code,
                selected: false,
            };
        });
        if (this.$store.getters['app/selectedCurrency'] !== null) {
            this.currency = this.$store.getters['app/selectedCurrency'].code;
        }
    }
    async beforeDestroy() {
        await this.resetPackageValues();
        // Reset destination data
        await this.$store.commit('destinations/SET_EMPTY');
        await this.$store.dispatch('packages/setExperience', null);
    }
    async resetCurrency() {
        this.currency = null;
        await this.$store.dispatch('app/setCurrency', null);
    }
    async filterFavourites() {
        this.onlyFavourites = !this.onlyFavourites;
        await this.$store.dispatch('packages/setOnlyFavourites', this.onlyFavourites);
        await this.getPackages();
    }
    async filterNonMalaria() {
        this.onlyNonMalaria = !this.onlyNonMalaria;
        await this.$store.dispatch('packages/setOnlyNonMalaria', this.onlyNonMalaria);
        await this.getPackages();
    }
    async toggleFilters() {
        this.showFilters = !this.showFilters;
        if (!this.showFilters) {
            this.destination = null;
            this.experience = null;
            await this.$store.dispatch('destinations/setDestination', null);
            await this.$store.dispatch('packages/setExperience', null);
            await this.$store.dispatch('packages/setFilter', '');
            await this.$store.dispatch('packages/setOnlyFavourites', false);
            await this.$store.dispatch('packages/setOnlyNonMalaria', false);
            await this.getPackages();
            this.onlyFavourites = false;
        }
    }
    async resetPackageValues() {
        await this.$store.commit('packages/SET_ONLY_FAVOURITES', false);
        await this.$store.commit('packages/SET_ONLY_NON_MALARIA', false);
        await this.$store.commit('packages/SET_ONLY_SPECIAL_OFFERS', false);
        await this.$store.dispatch('packages/setFilter', '');
        await this.$store.dispatch('packages/setPage', 1);
    }
    async paginate(page) {
        await this.$store.dispatch('packages/setPage', page);
        var query = {};
        query['page'] = page.toString();
        if (this.onlyNonMalaria) {
            query['non_malaria'] = this.onlyNonMalaria;
        }
        if (this.onlyFavourites) {
            query['favorite'] = this.onlyFavourites;
        }
        if (this.destination != null) {
            query['destination'] = this.destination;
        }
        if (this.experience != null) {
            query['experience'] = this.experience;
        }
        this.$router.push({
            name: 'packages',
            query: query,
        });
    }
};
__decorate([
    Getter('pagination', { namespace: 'pagination' })
], Packages.prototype, "pagination", void 0);
__decorate([
    Watch('currency')
], Packages.prototype, "currencyUpdated", null);
__decorate([
    Watch('destination')
], Packages.prototype, "destinationUpdated", null);
__decorate([
    Watch('experience')
], Packages.prototype, "experienceUpdated", null);
Packages = __decorate([
    Component({
        components: {
            Btn,
            PackageCard,
            TextInput,
            CoverImage,
            SelectInput,
            Pagination,
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | packages',
                meta: [
                    {
                        name: 'description',
                        content: 'Africa Geographic packages',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/packages` }],
            };
        },
    })
], Packages);
export default Packages;
